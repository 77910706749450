import NotionFrame from "../components/NotionFrame"
import Countdown from "react-countdown"

const Valentines = ({ notionData, setPage }) => {
  console.log(notionData)
  return (
    <>
      <NotionFrame html={notionData?.html} />
      <div
        className="countdown-standalone"
        onClick={() => setPage("next-chapter")}
      >
        <h3>
          <Countdown date={new Date(1685639340000)} />
        </h3>
        <p>Until the next chapter</p>
      </div>
    </>
  )
}

export default Valentines
