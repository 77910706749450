import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import notionData from "./data/notion"
import "./scss/main.scss"

const data = {
  notionData,
  countdownDate: new Date(1685639340000),
  initialPage: "next-chapter"
}

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <App data={data} />
  </React.StrictMode>
)
