import Countdown from "react-countdown"

const NextChapter = ({ countdownDate, setPage }) => {
  return (
    <main className="page countdown-page">
      <div className="countdown">
        <div className="countdown__container">
          <h3 className="countdown__title">The next chapter</h3>
          <h1 className="countdown__timer">
            <Countdown date={countdownDate} />
          </h1>
          <p className="countdown__subtitle">
            It's almost time for the next chapter. Who knows what it may hold
            and what twists and turns it may take, but one thing is for sure,
            it'll be{" "}
            <a href="https://extradope.com" rel="noreferrer" target="_blank">
              extra dope.
            </a>
          </p>
        </div>
        <div className="sticky-link" onClick={() => setPage("valentines")}>
          <p>Looking for your valentine's day card?</p>
        </div>
      </div>
    </main>
  )
}

export default NextChapter
