import { useState } from "react"
import NextChapter from "./pages/NextChapter"
import Valentines from "./pages/Valentines"

const pages = {
  "next-chapter": NextChapter,
  valentines: Valentines
}

const App = ({ data }) => {
  const [page, setPage] = useState(data.initialPage)

  const Page = pages[page]

  return <Page setPage={setPage} {...data} />
}

export default App
